import React from "react"
import McqsCard from "../components/McqsCard"

const Mcq = ({ pageContext }) => {
  const { ContextData } = pageContext
  const { frontmatter } = ContextData
  return (
    <div>
      <McqsCard
        title={frontmatter.title}
        options={frontmatter.options}
        slug={frontmatter.slug}
      />
    </div>
  )
}

export default Mcq

import React from "react"
import { graphql, Link } from "gatsby"

import Mcq from "./mcq"
import McqsCard from "../components/McqsCard"

const Category = ({ data, pageContext }) => {
  const { ContextData } = pageContext
  const { frontmatter } = ContextData
  const { html } = ContextData
  return (
    <div>
      {console.log("data", data)}
      {console.log("pageContext", pageContext)}
      <div className="blog-post">
        <h1 className="text-4xl text-center">{frontmatter.title}</h1>
      </div>

      {data.allMarkdownRemark.edges.map(edge => (
        <McqsCard
          title={edge.node.frontmatter.title}
          options={edge.node.frontmatter.options}
          slug={edge.node.frontmatter.slug}
        />
      ))}
    </div>
  )
}

export const Categoryquery = graphql`
  {
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "mcq" }, subject: { eq: "Chemistry" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            type
            title
            options
            slug
          }
        }
      }
    }
  }
`

export default Category
